<template>
	<div class="row h-100 justify-content-center align-items-center">
		<div class="wpx-300 text-center">
			<div class="card mb-5">
				<div class="card-body py-5">
					<i class="fal fa-exclamation-triangle font-60 text-secondary opacity-50 d-block mb-3 mt-5"></i>
					<h4 class="font-12 mt-4 mb-5 text-secondary text-uppercase">Função indisponível, contate o suporte e saiba mais.</h4>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

export default {
	name: 'Vendas'
}

</script>